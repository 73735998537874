import { message } from 'antd';
import { storage } from '../utils';
export const useAuth = () => {
	const loginFn = (password: string) => {
		if (password !== storage.getDate('INITIAL_PW')) {
			message.error('Password is incorrect');
			return;
		}
		storage.setToken(password);
		window.location.assign(window.location.origin as unknown as string);
	};
	const logoutFn = () => {
		storage.clear();
		window.location.assign(window.location.origin as unknown as string);
	};
	const isAuthenticated = storage.getToken() ? true : false;
	return {
		loginFn,
		logoutFn,
		isAuthenticated,
	};
};
