export const ENV = {
	ONESIGNAL: 'https://onesignal.com/api/v1',
	ONE_SIGNAL_TOKEN: 'ZTYzMmVmNzEtYjhjMC00N2I1LWE2MzItN2Y0NmE4MGM1NDVk',
	FIREBASE_CONFIG: {
		apiKey: 'AIzaSyA01dKxOalPSrTc3OwDedfExMlOuZE5Rig',
		authDomain: 'swire-push-notif-prod-1a62a.firebaseapp.com',
		projectId: 'swire-push-notif-prod-1a62a',
		storageBucket: 'swire-push-notif-prod-1a62a.appspot.com',
		messagingSenderId: '624358253906',
		appId: '1:624358253906:web:e1a4e17da208268ece068b',
		measurementId: 'G-YPBBLB2ZB5',
	},
	INITIAL_PW: '15Wa&9m0@2%F',
};
