import {
	ICreateNotificationDocument,
	IPlayersEntity,
} from '../interfaces/request.interfaces';
import { doc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore';

import { ENV } from '../ENV';
import { initializeApp } from 'firebase/app';
import { uid } from 'uid';

interface IBaseFilter {
	take: number;
}
export const FBService = {
	initializeApp() {
		return initializeApp(ENV.FIREBASE_CONFIG);
	},
	firestore() {
		const app = this.initializeApp();
		return getFirestore(app);
	},
	async createNotification(payload: ICreateNotificationDocument) {
		return await setDoc(doc(this.firestore(), 'notifications', uid(32)), {
			...payload,
		});
	},
	async createRecipient(payload: IPlayersEntity) {
		return await setDoc(doc(this.firestore(), 'recipients', uid(32)), {
			...payload,
			createdAt: serverTimestamp(),
		});
	},
};
