import * as papaparse from 'papaparse';

import React, { useState } from 'react';

import { AiOutlinePlus } from 'react-icons/ai';
import { Spin } from 'antd';
interface IFProps {
	onParsingComplete?: (data: any) => void;
	onParsingError?: (error: any) => void;
	title?: string;
	hideFileName?: boolean;
}
const CSVParser: React.FC<IFProps> = ({
	onParsingComplete,
	onParsingError,
	title = 'Import Recipients from CSV',
	hideFileName = null,
}) => {
	const [loading, setLoading] = useState(false);
	const [fileName, setFileName] = useState(null);
	const onFileChange = (e) => {
		setLoading(true);
		const file = e.target.files[0];
		setFileName(file?.name);
		papaparse.parse(file, {
			header: true,
			complete: function (results) {
				setTimeout(() => {
					setLoading(false);
					onParsingComplete?.(results.data);
				}, 500);
			},
			error: function (error) {
				setLoading(false);
				onParsingError?.(error);
			},
		});
	};
	return (
		<>
			{title && (
				<label className='block text-sm mb-3 font-medium text-gray-800'>
					Import Recipients from CSV
				</label>
			)}
			<div className='relative rounded-md h-28 w-28 rounded-md border-dashed border-2 flex justify-center items-center'>
				<input
					onChange={(e) => onFileChange(e)}
					type='file'
					className='absolute top-0 left-0 opacity-0 h-full w-full cursor-pointer'
					accept='.csv'
					disabled={loading}
				/>
				{loading && <Spin />}
				{!loading && <AiOutlinePlus size={22} className='opacity-70' />}
			</div>
			{!hideFileName && fileName && !loading && (
				<div
					className='flex py-2   justify-start  w-full h-full items-center'
					onClick={() => {
						setFileName(null);
					}}
				>
					<p className='text-xs text-gray-600 block'>{fileName}</p>
				</div>
			)}
		</>
	);
};
export default CSVParser;
