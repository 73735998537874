import { Button, Card, Form, Input, Typography } from 'antd';

import { IMAGES } from '../assets/index';
import { LockOutlined } from '@ant-design/icons';
import { useAuth } from '../hooks/useAuth';
import { useState } from 'react';
const LoginPage = () => {
	const [loading, setLoading] = useState(false);
	const { loginFn } = useAuth();
	const onSubmit = (v: any) => {
		setLoading(true);
		setTimeout(() => {
			loginFn(v.password);
			setLoading(false);
		}, 1000);
	};
	return (
		<div
			className='auth-page'
			style={{
				minHeight: '100vh',
				minWidth: '100vw',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				background: `url(${IMAGES.LightBd})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center right',
				backgroundRepeat: 'no-repeat',
			}}
		>
			<Card
				style={{
					maxWidth: '500px',
					width: '100%',
					boxShadow: '0 0 20px #0815420d',
					borderRadius: 10,
				}}
			>
				<Typography.Title
					level={2}
					style={{ textAlign: 'center', marginBottom: 30 }}
				>
					Sign In
				</Typography.Title>
				<Form
					layout='vertical'
					name='normal_login'
					initialValues={{ password: '123456' }}
					onFinish={onSubmit}
				>
					<Form.Item
						name='password'
						label='Password'
						rules={[{ required: true, message: 'Please input your Password!' }]}
					>
						<Input
							size='large'
							prefix={<LockOutlined className='site-form-item-icon' />}
							type='password'
							placeholder='Password'
						/>
					</Form.Item>
					<Form.Item>
						<Button
							size='large'
							style={{ display: 'block', width: '100%' }}
							type='primary'
							htmlType='submit'
							loading={loading}
						>
							Sign in
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</div>
	);
};
export default LoginPage;
