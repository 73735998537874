import { Outlet, useRoutes } from 'react-router-dom';

import CreateNewPushNotificationPage from '../pages/CreateNewPushNotificationPage';
import NotFound from '../components/NotFound';
import NotificationsPage from '../pages/NotificationsPage';
import UploadRecipients from '../pages/UploadRecipients';

const App = () => {
	let pathName = window.location.pathname;
	return (
		<>
			{pathName === '/' ? <NotificationsPage /> : ''}
			<Outlet />
		</>
	);
};
const ProtectedRoutes = () => {
	const routes = [
		{
			path: 'notifications',
			element: <NotificationsPage />,
		},
		{
			path: 'create-notification',
			element: <CreateNewPushNotificationPage />,
		},
		{
			path: 'upload-recipients',
			element: <UploadRecipients />,
		},
	];
	return useRoutes([
		{
			path: '',
			element: <App />,
			children: routes,
		},
		{
			path: '*',
			element: <NotFound />,
		},
	]);
};
export default ProtectedRoutes;
