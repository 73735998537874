import { Button, Card, Checkbox, Form, Input, message } from 'antd';
import {
	collection,
	getDocs,
	query,
	serverTimestamp,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { ApiService } from '../services/api.service';
import { AppLayout } from '../themes';
import { FBService } from '../services/firebase.service';
import { ICreateNotificationDocument } from '../interfaces/request.interfaces';
import { Link } from 'react-router-dom';
import { Status } from '../enums';
import { useNavigate } from 'react-router';

const CreateNewPushNotificationPage = () => {
	const navigation = useNavigate();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [recipients, setRecipients] = useState([]);
	const onFinish = async (values: any) => {
		onSendNotification({
			app_id: 'cc4ff899-5e6f-408d-93a1-1b2e9c0287ff',
			headings: { en: values?.title },
			include_player_ids: values?.recipients,
			channel_for_external_user_ids: 'push',
			contents: {
				en: values?.content,
			},
			name: '2023 swire',
			data: { expo_party_id: 127974 },
		});
	};
	const onSendNotification = async (values) => {
		setLoading(true);
		try {
			const response = await ApiService.sendNotification(values);
			if (!response?.id) return;
			await onSaveToFirebase({
				app_id: 'cc4ff899-5e6f-408d-93a1-1b2e9c0287ff',
				contents: {
					en: values?.contents.en,
				},
				headings: {
					en: values?.headings.en,
				},
				createdAt: serverTimestamp(),
				data: { expo_party_id: 127974 },
				sentRecipientsCount: response?.recipients,
				status: Status.DELIVERED,
				players: recipients,
				responseId: response?.id,
			});
			setLoading(false);
			message.success('Notification sent successfully');
			navigation('/notifications');
		} catch (error) {
			message.error('Notification sent failed');
			await onSaveToFirebase({
				app_id: 'cc4ff899-5e6f-408d-93a1-1b2e9c0287ff',
				contents: values?.contents,
				headings: values?.headings,
				createdAt: serverTimestamp(),
				data: { expo_party_id: 127974 },
				sentRecipientsCount: 0,
				status: Status.FAILED,
				players: recipients,
				responseId: null,
			});
			setLoading(false);
		}
	};
	const onSaveToFirebase = async (value: ICreateNotificationDocument) => {
		try {
			await FBService.createNotification(value);
		} catch (error) {}
	};
	const onSelectAll = (e: any) => {
		const isSelectAll = e.target.checked;
		if (isSelectAll) {
			form.setFieldsValue({
				recipients: recipients.map((recipient) => recipient['player_ids']),
			});
		} else {
			form.setFieldsValue({
				recipients: [],
			});
		}
		form.validateFields(['recipients']);
	};
	const fetchRecipients = async () => {
		setLoading(true);
		const first = query(collection(FBService.firestore(), 'recipients'));
		const documentSnapshots = await getDocs(first);
		const recipients = documentSnapshots.docs.map((doc) => doc.data());
		setRecipients(recipients);
		setLoading(false);
	};
	useEffect(() => {
		fetchRecipients();
		return () => {};
	}, []);
	return (
		<AppLayout pageTitle='Create a new push notification' loading={loading}>
			<Form
				form={form}
				layout='vertical'
				name='basic'
				initialValues={{ title: '', content: '', recipients: '' }}
				onFinish={onFinish}
				autoComplete='off'
			>
				<div className='grid lg:grid-cols-12 mt-10 gap-10'>
					<div className='col-span-6'>
						<Form.Item
							label='Push notification Title'
							name='title'
							rules={[
								{
									required: true,
									message: 'Please input your title!',
								},
								{
									max: 100,
									message: 'Title must be less than 100 characters',
								},
							]}
						>
							<Input size='large' />
						</Form.Item>
						<Form.Item
							label='Push notification content'
							name='content'
							rules={[
								{
									required: true,
									message: 'Please input your content!',
								},
								{
									max: 400,
									message: 'Content must be less than 400 characters',
								},
							]}
						>
							<Input.TextArea size='large' rows={6} showCount maxLength={100} />
						</Form.Item>
						<Form.Item>
							<div className='flex space-x-2'>
								<Button size='large' type='primary' htmlType='submit'>
									Send
								</Button>
							</div>
						</Form.Item>
					</div>
					<Card className='col-span-6 lg:order-2 order-1'>
						<div className='flex  my-3 justify-between'>
							<h5 className='font-semibold'>Please select recipients</h5>
							<Link to={'/upload-recipients'}>
								<Button size='small' type='primary'>
									Add Recipient
								</Button>
							</Link>
						</div>
						{recipients.length > 0 && (
							<Checkbox
								className='mb-3 '
								onChange={(e) => {
									onSelectAll(e);
								}}
							>
								Select all
							</Checkbox>
						)}
						<Form.Item
							name='recipients'
							rules={[
								{
									required: true,
									message: 'Please select at least one user!',
									type: 'array',
								},
							]}
						>
							<Checkbox.Group className='flex-col'>
								{recipients.map((recipient) => {
									return (
										<Checkbox
											className='!ml-[8px]'
											value={recipient['player_ids']}
											style={{ lineHeight: '32px' }}
										>
											{recipient['display email']}
										</Checkbox>
									);
								})}
							</Checkbox.Group>
						</Form.Item>
					</Card>
				</div>
			</Form>
		</AppLayout>
	);
};
export default CreateNewPushNotificationPage;
