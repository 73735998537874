import { Navigate, useRoutes } from 'react-router-dom';

import LoginPage from '../pages/LoginPage';

const PublicRoutes = () => {
	return useRoutes([
		{
			path: 'auth',
			element: <LoginPage />,
		},
		{
			path: '*',
			element: <Navigate to='/auth' />,
		},
	]);
};

export default PublicRoutes;
