import 'firebase/firestore';

import { AppProvider } from './context';
import AppRoutes from './routes';
import { ENV } from './ENV';
import { FBService } from './services/firebase.service';
import { storage } from './utils';
FBService.initializeApp();
const App = () => {
	storage.setData(ENV.INITIAL_PW, 'INITIAL_PW');
	return (
		<AppProvider>
			<AppRoutes />
		</AppProvider>
	);
};
export default App;
