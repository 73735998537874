import { Button, Table, Tag } from 'antd';
import {
	collection,
	getDocs,
	limit,
	orderBy,
	query,
	startAfter,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { AppLayout } from '../themes';
import { ColumnsType } from 'antd/lib/table';
import { FBService } from '../services/firebase.service';
import { Status } from '../enums';
import { fireBaseTimeToDate } from '../utils';
import moment from 'moment';
import { useNavigate } from 'react-router';

const NotificationsPage = () => {
	const [appLoading, setAppLoading] = useState(true);
	const [loadMoreLoading, setLoadMoreLoading] = useState(false);
	const [response, setResponse] = useState([]);
	const [lastVisible, setLastVisible] = useState<any>(null);
	const navigation = useNavigate();
	const columns: ColumnsType<any> = [
		{
			title: 'Message Name',
			dataIndex: 'messageName',
			key: 'messageName',
			render: (text) => <span className='font-semibold'>{text}</span>,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Sent At',
			dataIndex: 'sentAt',
			key: 'sentAt',
		},
		{
			title: 'Date',
			key: 'date',
			dataIndex: 'date',
		},
		{
			title: 'Sent',
			key: 'sent',
			dataIndex: 'sent',
		},
	];
	const data: any[] = response?.map((doc) => {
		const data = doc.data();
		const createdAt = fireBaseTimeToDate({
			seconds: data?.createdAt?.seconds,
			nanoseconds: data?.createdAt?.nanoseconds,
		});
		const statusTag = (status: string) => {
			switch (status) {
				case Status.DELIVERED:
					return <Tag color='green'>{Status.DELIVERED}</Tag>;
				case Status.FAILED:
					return <Tag color='magenta'>{Status.FAILED}</Tag>;
				default:
			}
		};
		return {
			key: doc?.id,
			messageName: data?.contents?.en,
			status: statusTag(data?.status),
			sentAt: moment(createdAt).format('DD/MM/YYYY HH:mm:ss A'),
			date: moment(createdAt).fromNow(),
			sent: data?.sentRecipientsCount,
		};
	});
	useEffect(() => {
		initialCall();
		return () => {};
	}, []);
	const initialCall = async () => {
		setAppLoading(true);
		const first = query(
			collection(FBService.firestore(), 'notifications'),
			limit(10),
			orderBy('createdAt', 'desc')
		);
		const documentSnapshots = await getDocs(first);
		const lastVisible =
			documentSnapshots.docs[documentSnapshots.docs.length - 1];
		setLastVisible(lastVisible);
		setResponse(documentSnapshots.docs);
		setAppLoading(false);
	};
	const onLoadMore = async () => {
		setLoadMoreLoading(true);
		const next = query(
			collection(FBService.firestore(), 'notifications'),
			orderBy('createdAt', 'desc'),
			startAfter(lastVisible),
			limit(10)
		);
		const documentSnapshots = await getDocs(next);
		const nextlastVisible =
			documentSnapshots.docs[documentSnapshots.docs.length - 1];
		setLastVisible(nextlastVisible);
		setResponse([...response, ...documentSnapshots.docs]);
		setLoadMoreLoading(false);
	};
	return (
		<AppLayout pageTitle='List of sent Push notifications'>
			<div className='py-5 flex'>
				<Button
					onClick={() => {
						navigation('/create-notification');
					}}
					type='primary'
					size='middle'
				>
					New Push Notification
				</Button>
			</div>
			<Table
				size='small'
				columns={columns}
				dataSource={data}
				loading={appLoading}
				pagination={false}
				footer={() => (
					<div className='flex justify-center'>
						<Button
							loading={loadMoreLoading}
							onClick={() => {
								onLoadMore();
							}}
							type='primary'
							size='middle'
						>
							Load More
						</Button>
					</div>
				)}
			/>
		</AppLayout>
	);
};
export default NotificationsPage;
