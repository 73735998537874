import './style.scss';

import {
	AiOutlineMenuFold,
	AiOutlineMenuUnfold,
	AiOutlineOrderedList,
	AiOutlinePlus,
	AiOutlineUpload,
} from 'react-icons/ai';
import { Avatar, Dropdown, Layout, Menu, Spin } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from 'ahooks';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
interface IFProps {
	children: ReactNode;
	pageTitle?: string;
	loading?: boolean;
}
export const AppLayout: React.FC<IFProps> = ({
	children,
	pageTitle,
	loading,
}) => {
	const responsive = useResponsive();
	const { logoutFn } = useAuth();
	const isMobileView = responsive.lg === false;
	const [sideBarCollapse, setSideBarCollapse] = useState(false);
	const navigation = useNavigate();
	useEffect(() => {
		if (isMobileView) {
			setSideBarCollapse(true);
		}
		// eslint-disable-next-line
	}, []);
	const menu = (
		<Menu className='winter__dropdown'>
			<Menu.Item
				className='winter__dropdown__item'
				onClick={() => {
					logoutFn();
				}}
			>
				Logout
			</Menu.Item>
		</Menu>
	);
	return (
		<Layout className='winter-layout'>
			<Sider
				trigger={null}
				collapsible
				collapsed={sideBarCollapse}
				className='winter-layout__sider bg-[#001529]'
				style={{
					position: 'absolute',
					left: 0,
					height: '100%',
					background: '#001529',
				}}
				collapsedWidth={0}
				width={200}
			>
				<div className='py-5 flex pl-5 justify-start items-center'>
					<img src={'IMAGES.Logo'} className='w-8' alt='' />
					<p className='font-medium text-base ml-2 text-white'>Company Name</p>
					<div className='absolute right-4 cursor-pointer lg:hidden'>
						<AiOutlineMenuFold
							onClick={() => {
								setSideBarCollapse(true);
							}}
							className='text-xl text-secondary '
						/>
					</div>
				</div>
				<Menu
					theme='dark'
					defaultSelectedKeys={['1']}
					mode='inline'
					className='border-none'
				>
					<Menu.Item
						onClick={() => navigation('/create-notification')}
						key='create-notification'
						icon={<AiOutlinePlus />}
					>
						New Push Notification
					</Menu.Item>
					<Menu.Item
						onClick={() => navigation('/notifications')}
						key='notifications'
						icon={<AiOutlineOrderedList />}
					>
						Notifications Lists
					</Menu.Item>
					<Menu.Item
						onClick={() => navigation('/upload-recipients')}
						key='upload-recipients'
						icon={<AiOutlineUpload />}
					>
						Upload Recipients
					</Menu.Item>
				</Menu>
			</Sider>
			<Layout
				className='winter-layout__inner'
				style={{
					marginLeft: isMobileView ? 0 : 200,
				}}
			>
				<Header
					className='header'
					style={{
						marginLeft: isMobileView ? 0 : 200,
					}}
				>
					<div className='header__left'>
						<h3 className='header__sidebar__toggler inline-block lg:hidden'>
							<AiOutlineMenuUnfold
								className='text-xl mr-2 text-secondary cursor-pointer'
								onClick={() => {
									setSideBarCollapse(false);
								}}
							/>
						</h3>
						<h3 className='header__page-title'>{pageTitle}</h3>
					</div>
					<div className='header__right'>
						<div className='header__actions'>
							<Dropdown overlay={menu}>
								<div className='flex items-center cursor-pointer'>
									<Avatar
										className='mr-3'
										style={{ backgroundColor: '#87d068' }}
										icon={<UserOutlined />}
									/>
									<DownOutlined />
								</div>
							</Dropdown>
						</div>
					</div>
				</Header>
				<Content className='winter-layout__content'>
					<Spin spinning={loading || false}>{children}</Spin>
				</Content>
			</Layout>
		</Layout>
	);
};
