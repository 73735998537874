import { ENV } from '../ENV';
import { ICreateNewNotificationRequest } from '../interfaces/request.interfaces';

export const ApiService = {
	sendNotification: (data: ICreateNewNotificationRequest) => {
		return fetch(`${ENV.ONESIGNAL}/notifications`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Basic ${ENV.ONE_SIGNAL_TOKEN}`,
			},
			body: JSON.stringify(data),
		}).then((res) => res.json());
	},
};
