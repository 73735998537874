const storagePrefix = 'EVENTX_';
type KEYS = 'INITIAL_PW';
export const storage = {
	getToken: (): string | false => {
		let item: any = localStorage.getItem(`${storagePrefix}token`);
		return JSON.parse(item) as string;
	},
	setToken: (token: string) => {
		localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
	},
	clear: () => {
		localStorage.clear();
	},
	setData(data: any, key: KEYS) {
		localStorage.setItem(key, JSON.stringify(data));
	},
	getDate(key: KEYS) {
		let item = localStorage.getItem(key);
		if (!item) {
			return;
		}
		return JSON.parse(item);
	},
	removeData(key: KEYS) {
		localStorage.removeItem(key);
	},
};
export const fireBaseTimeToDate = ({ nanoseconds, seconds }) => {
	return new Date(seconds * 1000 + nanoseconds / 1000000);
};

export const asyncForEach = async (array: any[], callback: any) => {
	if (!Array.isArray(array)) {
		throw new Error('First argument must be an array');
	}
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array);
	}
};
