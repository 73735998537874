import { Button, Card, Modal, Table, message } from 'antd';
import { asyncForEach, fireBaseTimeToDate } from '../utils';
import { collection, deleteDoc, doc, getDocs, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { AppLayout } from '../themes';
import CSVParser from '../components/CSVParser';
import { ColumnsType } from 'antd/lib/table';
import { FBService } from '../services/firebase.service';
import moment from 'moment';

const UploadRecipients = () => {
	const [recipients, setRecipients] = useState([]);
	const [lastVisible, setLastVisible] = useState<any>(null);
	const [response, setResponse] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadMoreLoading, setLoadMoreLoading] = useState(false);
	const onSubmit = async () => {
		setLoading(true);
		try {
			await asyncForEach(recipients, async (recipient) => {
				await FBService.createRecipient(recipient);
			});
			message.success('Recipients uploaded successfully');
			setLoading(false);
			setRecipients([]);
			initialCall();
		} catch (error) {
			setLoading(false);
		}
	};
	useEffect(() => {
		initialCall();
		return () => {};
	}, []);
	const initialCall = async () => {
		setLoading(true);
		const first = query(collection(FBService.firestore(), 'recipients'));
		const documentSnapshots = await getDocs(first);
		const lastVisible =
			documentSnapshots.docs[documentSnapshots.docs.length - 1];
		setLastVisible(lastVisible);
		setResponse(documentSnapshots.docs);
		setLoading(false);
	};
	const onDelete = async (id: string) => {
		Modal.confirm({
			title: 'Are you sure you want to delete this recipient?',
			onOk: async () => {
				setLoading(true);
				try {
					await deleteDoc(doc(FBService.firestore(), 'recipients', id));
					message.success('Recipient deleted successfully');
					setLoading(false);
					initialCall();
				} catch (error) {
					setLoading(false);
				}
			},
		});
	};
	const columns: ColumnsType<any> = [
		{
			title: 'App User Email',
			dataIndex: 'appUserEmail',
			key: 'appUserEmail',
			render: (text) => <span className='font-semibold'>{text}</span>,
		},
		{
			title: 'createdAt',
			dataIndex: 'createdAt',
			key: 'createdAt',
		},
		{
			title: 'Display Email',
			dataIndex: 'displayEmail',
			key: 'displayEmail',
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => (
				<Button
					danger
					onClick={() => {
						onDelete(record.key);
					}}
					type='primary'
					className='mr-2'
				>
					Delete
				</Button>
			),
		},
	];
	const data: any[] = response?.map((doc) => {
		const data = doc.data();
		const createdAt = fireBaseTimeToDate({
			seconds: data?.createdAt?.seconds,
			nanoseconds: data?.createdAt?.nanoseconds,
		});
		return {
			key: doc?.id,
			appUserEmail: data['app user email'],
			createdAt: moment(createdAt).format('DD/MM/YYYY HH:mm:ss A'),
			displayEmail: data['display email'],
		};
	});
	const onDeleteAll = async () => {
		Modal.confirm({
			title: 'Are you sure you want to delete all recipients?',
			onOk: async () => {
				setLoading(true);
				try {
					await asyncForEach(response, async (data) => {
						await deleteDoc(doc(FBService.firestore(), 'recipients', data.id));
					});
					message.success('Recipients deleted successfully');
					setLoading(false);
					initialCall();
				} catch (error) {
					setLoading(false);
				}
				setLoading(false);
			},
		});
	};
	return (
		<AppLayout pageTitle='Upload Recipients' loading={loading}>
			<Card title='Import Recipients from CSV'>
				<div className='flex justify-center'>
					<div className='flex flex-col'>
						<CSVParser
							hideFileName={recipients.length <= 0}
							title=''
							onParsingComplete={(res) => {
								setRecipients(res);
							}}
							onParsingError={() => {}}
						/>
						<Button onClick={onSubmit} type='primary' className='mt-5 '>
							Upload
						</Button>
					</div>
				</div>
			</Card>
			{response?.length > 0 && (
				<Button className='mt-5' type='primary' danger onClick={onDeleteAll}>
					Delete All
				</Button>
			)}
			<Table
				showHeader={true}
				className='mt-5'
				size='small'
				columns={columns}
				dataSource={data}
				loading={false}
				pagination={false}
			/>
		</AppLayout>
	);
};
export default UploadRecipients;
